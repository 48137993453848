import {SingleProduct} from "../interfaces/product-interface";
import {config} from "../config-merchant";
import {Question} from "../interfaces/utils-interfaces";

export const getProductSchemaData = (product: SingleProduct) => {
    const priceEnd = new Date(Date.now() + 12096e5); // +14 jours
    const schemaData =
        {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": stripHtml(product.name),
            "description": stripHtml(product.description),
            "image": product.images[0],
            "category": config.merchantDomain + "catalog?category=" + product.category.categoryId,
            "sku": product.productId,
            "brand": {
                "@type": "Brand",
                "name": ""
            },
            "review": product.reviews === null ? "" : product.reviews.map(rev => {
                return {
                    "@type": "Review",
                    "author": {
                        "@type": "Person",
                        "name": rev.author
                    },
                    "datePublished": new Date(rev.reviewDate),
                    "reviewBody": rev.review,
                    "reviewRating": {
                        "@type": "Rating",
                        "bestRating": "5",
                        "ratingValue": rev.score,
                        "worstRating": "1"
                    }
                }
            }),
            "aggregateRating": product.score === null ? "" : {
                "@type": "AggregateRating",
                "ratingValue": product.score,
                "ratingCount": product.reviews.length,
                "bestRating": "5",
                "worstRating": "1"
            },
            "gtin": product.gtin === null ? "" : product.gtin,
            "offers": product.prices.map(price => {
                return {
                    "@type": "Offer",
                    "url": config.merchantDomain + "product/" + product.productSlug,
                    "itemCondition": "https://schema.org/NewCondition",
                    "availability": product.availableInStock ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
                    "price": price.amountWithTax,
                    "priceCurrency": price.currency,
                    "priceValidUntil": priceEnd,
                }
            })
        };
    if (product.productRangeCode) {
        schemaData["inProductGroupWithID"] = product.productRangeCode;
    }
    return schemaData
}

export const getWebsiteSchemaData = () => {
    return {
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": config.merchantShopName,
        "url": config.merchantDomain,
        "potentialAction": [
            {
                "@type": "SearchAction",
                "target": config.merchantDomain + "catalog?research={search_term_string}",
                "query-input": "required name=search_term_string"
            }
        ]
    };
}

export const getBreadcrumbProductSchemaData = (product: SingleProduct, t: (string) => string) => {
    return {
        "@context": "https://schema.org/",
        "@type": "BreadcrumbList",
        "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": t("NAV_CATALOG"),
            "item": config.merchantDomain + "catalog"
        }, {
            "@type": "ListItem",
            "position": 2,
            "name": stripHtml(product.category.name),
            "item": config.merchantDomain + "catalog?category=" + product.category.categoryId
        }, {
            "@type": "ListItem",
            "position": 3,
            "name": stripHtml(product.name),
            "item": config.merchantDomain + "product/" + product.productSlug,
        }]

    };
}

export const getFAQSchemaData = (questions: Question[]) => {
    return {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": questions.map(question => {
            return {
                "@type": "Question",
                "name": question.question,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<div>" + question.answer + "</div>"
                }
            }
        })
    }

}

const stripHtml = (html) => {
    let tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}
