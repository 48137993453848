import {config} from '../config-merchant';
import {Category, Product, ProductRange, Tag} from '../interfaces/catalog-interfaces';
import {appyShopConfig} from '../config'

const axios = require('axios');

export function getCategories(language: string): Promise<Category[]> {
    return axios.get(appyShopConfig.apiUrl + config.merchantId + '/' + language + '/catalog/categories')
}

export function getTags(language: string): Promise<Tag[]> {
    return axios.get(appyShopConfig.apiUrl + config.merchantId + '/' + language + '/catalog/tags')
}

export function getAllItems(currency: string, language: string, page: number, sortType: string): Promise<(Product | ProductRange)[]> {
    return axios.get(appyShopConfig.apiUrl + config.merchantId + '/' + language + '/catalog/products/' + currency + '/' + sortType + '/' + page + '/' + config.nbProductsByPage)
}

export function getItemsByCategory(currency: string, language: string, page: number, sortType: string, categoryId: number): Promise<(Product | ProductRange)[]> {
    return axios.get(appyShopConfig.apiUrl + config.merchantId + '/' + language + '/catalog/products/' + currency + '/' + categoryId + '/' + sortType + '/' + page + '/' + config.nbProductsByPage)
}

export function getItemsByResearch(currency: string, language: string, page: number, sortType: string, query: string, category: number, tags: number[]): Promise<(Product | ProductRange)[]> {
    return axios.post(appyShopConfig.apiUrl + config.merchantId + '/' + language + '/catalog/products/search/' + currency + '/' + sortType + '/' + page + '/' + config.nbProductsByPage, {
        "query": query,
        "tags": tags,
        "category": category === 0 ? null : category
    })
}