module.exports = {
    internalization: {
        languages: {
            fr: "Français",
            en: "English"
        },
        currencies: {
            EUR: {symbol: '€', text: 'Euros'},
            USD: {symbol: '$', text: 'Dollars'},
            CHF: {symbol: 'CHF', text: 'Franc suisse'}
        }
    },
    euCountries: ['at', 'be', 'bg', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gr', 'hr', 'hu', 'ie', 'it', 'lt',
        'lu', 'lv', 'mt', 'nl', 'pl', 'pt', 'ro', 'se', 'si', 'sk']
}
