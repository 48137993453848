import {I18n, useTranslation, withTranslation} from 'next-i18next';
import {config} from '../../config-merchant';
import Link from 'next/link';
import _variables from '../../styles/global.module.scss';
import {useRouter} from "next/router";
import {internalization} from '../../config-internalization';
import {ShopState} from "../../interfaces/store-interfaces";
import {updateCurrency} from "../../store/action";
import {connect} from "react-redux";

function FooterAppyShop(props: { currentCurrency: string, i18n: I18n, updateCurrency: any }) {

    const router = useRouter();
    const {t} = useTranslation('common');
    const socialMediasIcons = [
        {
            "name": "facebook",
            "icon": <svg xmlns="http://www.w3.org/2000/svg" width="54.586" height="54.585" viewBox="0 0 54.586 54.585">
                <defs>
                    <style>{'.a{fill:#312c30;}'}</style>
                </defs>
                <g transform="translate(-372.808 -202.947)">
                    <path className="a"
                          d="M400.1,257.532a27.293,27.293,0,1,1,27.293-27.292A27.324,27.324,0,0,1,400.1,257.532Zm0-51.653a24.361,24.361,0,1,0,24.361,24.361A24.388,24.388,0,0,0,400.1,205.879Z"/>
                    <path className="a"
                          d="M395.821,230.369v15.818a.412.412,0,0,0,.412.412h5.874a.412.412,0,0,0,.412-.412V230.111h4.259a.411.411,0,0,0,.41-.377l.41-4.844a.413.413,0,0,0-.411-.447h-4.668v-3.436a1.459,1.459,0,0,1,1.459-1.459h3.282a.412.412,0,0,0,.412-.412v-4.843a.412.412,0,0,0-.412-.412h-5.546a5.893,5.893,0,0,0-5.893,5.893v4.669h-2.937a.412.412,0,0,0-.412.412V229.7a.411.411,0,0,0,.412.412h2.937Z"/>
                </g>
            </svg>
        },
        {
            "name": "instagram",
            "icon": <svg xmlns="http://www.w3.org/2000/svg" width="54.229" height="54.229" viewBox="0 0 54.229 54.229">
                <defs>
                    <style>{'.a{fill:#312c30;}'}</style>
                </defs>
                <g transform="translate(-454.891 -203.631)">
                    <path className="a"
                          d="M482.006,257.86a27.114,27.114,0,1,1,27.114-27.114A27.146,27.146,0,0,1,482.006,257.86Zm0-51.653a24.538,24.538,0,1,0,24.538,24.539A24.567,24.567,0,0,0,482.006,206.207Zm9.142,13.537a2.007,2.007,0,1,0,2.007,2.007A2.007,2.007,0,0,0,491.148,219.744Zm-8.92,2.573a8.428,8.428,0,1,0,8.429,8.429A8.438,8.438,0,0,0,482.228,222.317Zm0,13.828a5.4,5.4,0,1,1,5.4-5.4A5.405,5.405,0,0,1,482.228,236.145Zm17.007-12.4a10.281,10.281,0,0,0-10.281-10.28H475.386a10.281,10.281,0,0,0-10.28,10.28V237.31a10.281,10.281,0,0,0,10.28,10.28h13.568a10.281,10.281,0,0,0,10.281-10.28Zm-3.22,13.569a7.06,7.06,0,0,1-7.061,7.06H475.386a7.06,7.06,0,0,1-7.06-7.06V223.741a7.06,7.06,0,0,1,7.06-7.06h13.568a7.06,7.06,0,0,1,7.061,7.06Z"/>
                </g>
            </svg>
        },
        {
            "name": "youtube",
            "icon": <svg xmlns="http://www.w3.org/2000/svg" width="54.585" height="54.586" viewBox="0 0 54.585 54.586">
                <defs>
                    <style>{'.a{fill:#312c30;}'}</style>
                </defs>
                <g transform="translate(-454.713 -138.718)">
                    <path className="a"
                          d="M482.006,193.3A27.293,27.293,0,1,1,509.3,166.011,27.324,27.324,0,0,1,482.006,193.3Zm0-51.654a24.361,24.361,0,1,0,24.36,24.361A24.388,24.388,0,0,0,482.006,141.65Z"/>
                </g>
                <g transform="translate(-454.713 -138.718)">
                    <path className="a"
                          d="M498.347,161.872a7.009,7.009,0,0,0-7.009-7.009H473.159a7.009,7.009,0,0,0-7.008,7.009v8.52a7.009,7.009,0,0,0,7.008,7.009h18.179a7.009,7.009,0,0,0,7.009-7.009Zm-11.469,4.689-7.25,3.976c-.315.171-.6-.057-.6-.416v-8.162c0-.363.293-.59.608-.411l7.3,4.186A.475.475,0,0,1,486.878,166.561Z"/>
                </g>
            </svg>
        }
    ]
    return (
        <footer>
            {/* <!--Informations section--> */}
            <div className="footer-infos">
                {/* <!--Address--> */}
                <div className="infos">
                    <div className="info">
                        <a href={config.shopMapsUrl !== "" ? config.shopMapsUrl : "http://maps.google.com/?q=" + config.shopAddress + " " + config.shopPostalCity + " " + config.shopCountry}
                           target="_blank">
                            <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 20 30"
                                     fill={_variables.colorBase}>
                                    <path d="M15.627,9.5,19.055,5H7.857v9h11.2ZM25,17H7.857V32H5V2H25L19.286,9.5Z"
                                          transform="translate(-5 -2)"/>
                                </svg>
                            </div>
                            <p className="text">{config.shopAddress}<br/>{config.shopPostalCity + ' - ' + config.shopCountry}
                            </p>
                        </a>
                    </div>
                    {/* <!--Phone-->
                    <div className="info">
                        <a href={"tel:+" + config.shopPhone}>
                            <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28.155" height="28.155"
                                     viewBox="0 0 28.155 28.155" fill={_variables.colorBase}>
                                    <path d="M22,12A10,10,0,0,0,12,2V4a8,8,0,0,1,8,8Z" transform="translate(6.155 -2)"/>
                                    <path
                                        d="M2,12.718V5.453A1.453,1.453,0,0,1,3.453,4h7.265a1.453,1.453,0,0,1,1.453,1.453v5.812a1.453,1.453,0,0,1-1.453,1.453H7.812A11.625,11.625,0,0,0,19.437,24.343V21.437a1.453,1.453,0,0,1,1.453-1.453H26.7a1.453,1.453,0,0,1,1.453,1.453V28.7A1.453,1.453,0,0,1,26.7,30.155H19.437A17.437,17.437,0,0,1,2,12.718Z"
                                        transform="translate(-2 -2)"/>
                                    <path
                                        d="M17.543,9.7A6,6,0,0,1,18,12H16.2A4.2,4.2,0,0,0,12,7.8V6a6,6,0,0,1,5.543,3.7Z"
                                        transform="translate(3.825 0.33)"/>
                                </svg>
                            </div>
                            <p className="text">{config.shopPhone}</p>
                        </a>
                    </div>*/}
                    {/* <!--Mail--> */}
                    <div className="info">
                        <a href={"mailto:" + config.shopEmail}>
                            <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                                     fill={_variables.colorBase}>
                                    <path d="M9,14a1,1,0,1,0-1-1A1,1,0,0,0,9,14Z" transform="translate(2 4.667)"
                                          fill={_variables.colorBase}/>
                                    <path d="M16,13a1,1,0,1,1-1-1A1,1,0,0,1,16,13Z" transform="translate(6 4.667)"
                                          fill={_variables.colorBase}/>
                                    <path fillRule={"evenodd"}
                                          fill={_variables.colorBase}
                                          d="M18,34A16,16,0,1,0,2,18,16,16,0,0,0,18,34Zm0-3.2A12.81,12.81,0,0,0,30.214,14.16a16.02,16.02,0,0,1-4.19.554A15.994,15.994,0,0,1,12.116,6.629,12.8,12.8,0,0,0,18,30.8Z"
                                          transform="translate(-2 -2)"/>
                                </svg>
                            </div>
                            <p className="text">{config.shopEmail}</p>
                        </a>
                    </div>
                </div>
            </div>
            {/* <!--Others Links section : Socials medias and Legals--> */}
            <div className="footer-links">
                {/* <!--Legals links--> */}
                <div className="links">
                    <ul>
                        {config.footerLink.map((link, index) =>
                            <Link key={index} href={link.url}>
                                <li>{t(link.name)}</li>
                            </Link>)}
                    </ul>
                </div>
                {/* <!--Socials medias--> */}
                <div className="socials-medias">
                    {
                        Object.entries(config.socialMedias).map((media) => <a key={media[0]} href={media[1]}
                                                                              target="_blank">{socialMediasIcons.find(mediaIcon => media[0] === mediaIcon.name).icon}</a>)
                    }
                </div>
            </div>
            {/* <!--Language/Currency section--> */}
            {!(config.enabledLanguagues.length === 1 && config.enabledCurrencies.length === 1) &&
                <div className="footer-language-currency">
                    <p>
                        {config.enabledLanguagues.length > 1 && config.enabledLanguagues.map((language, key) =>
                            router.pathname === '/product/[id]' ?
                                <Link key={key} href={'/catalog'} locale={language}>
                                    <span className={router.locale === language ? "active" : ""}>
                                        {internalization.languages[language]}
                                    </span>
                                </Link>
                                :
                                <Link key={key} href={router.asPath} locale={language}>
                                    <span className={router.locale === language ? "active" : ""}>
                                        {internalization.languages[language]}
                                    </span>
                                </Link>
                        )}

                        {config.enabledLanguagues.length > 1 && config.enabledCurrencies.length > 1 &&
                            <span className="separator"/>}

                        {config.enabledCurrencies.length > 1 && config.enabledCurrencies.map((currency, key) =>
                            <span key={key} onClick={() => props.updateCurrency(currency)}
                                  className={currency === props.currentCurrency ? "active" : ""}>{internalization.currencies[currency]['text'] + ' (' + internalization.currencies[currency]['symbol'] + ')'}</span>
                        )}
                    </p>
                </div>
            }
            {/* <!--Copyright section--> */}
            <div className="footer-copy">
                <img src="/assets/svg/logo-horizontal.svg" alt="logo-footer"/>
                <p
                    dangerouslySetInnerHTML={{
                        __html: t('FOOTER_COPYRIGHT')
                            .replace('%YEAR%', "" + new Date().getFullYear())
                            .replace('%SHOP%', config.merchantShopName)
                            .replace('%APPYSHOP%', '<a href="https://appyshop.fr" target="_blank">AppyShop</a>')
                            .replace('%WMDEV%', '<a href="https://wm-dev.fr" target="_blank">WM Dev</a>')
                    }}
                />
            </div>
        </footer>
    )
}

const mapState = (state: ShopState) => ({
    currentCurrency: state.currentCurrency,
})

const mapDispatch = (dispatch) => {
    return {
        updateCurrency: (currency) => dispatch(updateCurrency(currency))
    }
}

export default connect(mapState, mapDispatch)(withTranslation('common')(FooterAppyShop))
