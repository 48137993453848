import Head from 'next/head'
import {config} from '../../config-merchant'
import {useEffect, useState} from "react";
import {
    getBreadcrumbProductSchemaData,
    getFAQSchemaData,
    getProductSchemaData,
    getWebsiteSchemaData
} from "../../utils/schema-data";
import {SingleProduct} from "../../interfaces/product-interface";
import {useTranslation} from "next-i18next";
import {Question} from "../../interfaces/utils-interfaces";

export default function Meta(props: {
    title: string,
    description?: string,
    metaRobots: boolean,
    image?: string,
    product?: SingleProduct,
    faq?: Question[]
}) {

    const [schemaData, setSchemaData] = useState([]);
    const {t} = useTranslation('common');

    useEffect(() => {
        const schemas = [];
        schemas.push(getWebsiteSchemaData());
        if (props.product !== undefined) {
            schemas.push(getProductSchemaData(props.product));
            schemas.push(getBreadcrumbProductSchemaData(props.product, t));
        }
        if(props.faq !== undefined) {
            schemas.push(getFAQSchemaData(props.faq));
        }
        setSchemaData(schemas);
    }, [])

    return (
        <Head>
            <title>{props.title === "" ? config.merchantShopName : props.title + ' | ' + config.merchantShopName}</title>
            <meta property="og:title"
                  content={props.title === "" ? config.merchantShopName : props.title + ' | ' + config.merchantShopName}/>
            {
                props.description !== undefined &&
                <>
                    <meta name="description" content={props.description}/>
                    <meta property="og:description" content={props.description}/>
                </>
            }
            {props.metaRobots ?
                <meta name="robots" content="index, follow"/>
                :
                <meta name="robots" content="noindex, nofollow"/>
            }
            {
                props.image !== undefined &&
                <>
                    <meta property="og:image" content={props.image}/>
                    <meta name="twitter:card" content={props.image}/>
                </>
            }
            <link rel="apple-touch-icon" sizes="57x57" href="/assets/images/favicons/android-icon-192x192.png"/>
            <link rel="apple-touch-icon" sizes="60x60" href="/assets/images/favicons/apple-icon-57x57.png"/>
            <link rel="apple-touch-icon" sizes="60x60" href="/assets/images/favicons/apple-icon-60x60.png"/>
            <link rel="apple-touch-icon" sizes="60x60" href="/assets/images/favicons/apple-icon-72x72.png"/>
            <link rel="apple-touch-icon" sizes="60x60" href="/assets/images/favicons/apple-icon-76x76.png"/>
            <link rel="apple-touch-icon" sizes="60x60" href="/assets/images/favicons/apple-icon-114x114.png"/>
            <link rel="apple-touch-icon" sizes="60x60" href="/assets/images/favicons/apple-icon-120x120.png"/>
            <link rel="apple-touch-icon" sizes="60x60" href="/assets/images/favicons/apple-icon-144x144.png"/>
            <link rel="apple-touch-icon" sizes="60x60" href="/assets/images/favicons/apple-icon-152x152.png"/>
            <link rel="apple-touch-icon" sizes="60x60" href="/assets/images/favicons/apple-icon-180x180.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/assets/images/favicons/favicon-16x16.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/assets/images/favicons/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/assets/images/favicons/favicon-96x96.png"/>
            <link rel="icon" href="/assets/images/favicons/favicon.ico"/>
            <link rel="manifest" href="/assets/images/favicons/manifest.json"></link>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: JSON.stringify(schemaData)}}/>
        </Head>
    )
}
